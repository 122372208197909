import React from "react"
import {Helmet} from "react-helmet"
import { css } from "react-emotion"
import { StaticQuery, Link, graphql } from "gatsby"

import styles from "./App/Header/header.module.css"
import { rhythm } from "../utils/typography"

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
}
    render={data => (
      <div >

      <Helmet>
        <meta charSet="utf-8" />
        <title>Global Rez</title>
       </Helmet>

      <header className={styles.header}>
        <div className={styles[`header__wrap`]}>
          <h1 className={styles[`header__heading`]}>
            <Link
              to="/"
              className={`${styles[`header__link`]} ${
                styles[`header__link--home`]
              }`}
            >
              Global Rez
            </Link>
          </h1>
           
        </div>
      </header>

 
        <div
         className={css`
          margin: 0 auto;
          max-width: 700px;
          padding: ${rhythm(2)};
          padding-top: ${rhythm(1.5)};
        `}
        >
        {children}
        </div>
      </div>
    )}
  />
)