import React from "react" 
import { css } from "react-emotion"
import { Link, graphql } from "gatsby"
import { rhythm } from "../utils/typography"
 
import Layout from "../components/layout" 


import {
  InstantSearch,
  Hits,
  SearchBox,
  Highlight
} from 'react-instantsearch-dom';

const config = {
  algolia: {
    appId: 'EDNAUE78ON',
    apiKey: '42c0ead8d94d2c9a3ab40cc43729dacd',
    indexName : 'GREZ'
  }
}


export default ( ) => { 
  return (
      <Layout> 
      <div> 
       
            <Link style={{marginLeft : 10}}  to={`/en/`} >
              English
            </Link> 
            <br/>
            <Link style={{marginLeft : 10}}  to={`/es/`} >
              Espanol
            </Link>

          <div>
            
  

          </div>
       

      </div>

    </Layout>
  )
}
